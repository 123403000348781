<template> 
<div>
    <v-list-item :to="compTo" exact :class="{'opacity-50': compDisabled}" :title="compTitle" @click="onClick">
        <v-list-item-action v-if="!noIcon" class="mr-1">
            <v-icon :color="color" v-if="compIcon" small>{{compIcon}}</v-icon>
        </v-list-item-action>
        <v-list-item-content >
            <v-list-item-title>
                <slot>{{compText}}</slot>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="count">
            <span style="font-size:smaller">{{count}}</span>
        </v-list-item-action>
    </v-list-item>
</div>
</template>

<script>
import icon from '@app/icon'
import eventbus from '@app/eventbus.js';
import navigation from '@app/navigation'

/*

    Usage: 
    
        import RouteMenuItem from "@controls/menu/RouteMenuItem.vue";

        <v-list dense>
            <RouteMenuItem route="" to="/facturen/problemen/email" type="settings">1e herinnering</RouteMenuItem> 
            <RouteMenuItem route="" to="/facturen/instellingen" icon="mdi-send-clock">2e herinnering</RouteMenuItem> 
            <RouteMenuItem route="" to="/facturen/help" icon="mdi-send-circle">Ingebrekestelling</RouteMenuItem> 
        </v-list>              
        
*/

export default {
    name: 'RouteMenuItem',
    components: {
    },
    props: {
        icon: {
            type: [String]
        },
        type: {
            type: [String],
            default: ""
        },
        disabled: {
            type: [Boolean],
            default: false
        },
        to: {
            type: [String],
            default: ""
        },
        color: {
            type: [String],
            default: ""
        },
        count: {
            type: [String, Number],
            default: ""
        },
        noIcon: {
            type: [Boolean],
            default: false
        },
        // Show the text of the root menu - e.g. 'Instellingen' i.s.o. 'Units'.
        showRootText: {
            type: [Boolean],
            default: false
        },
        route: {
            type: [String, Object, Array]
        },     
        path: {
            type: [String]
        },     
    },    
    data () {
        return {             
            pageItem: {},
        }
    },

    methods: {
        onClick() {
            if (this.pageItem && !this.pageItem.isAuthorized) {
                let alert = {
                    title: "Geen rechten",
                    body:  this.msgNoRights
                };

                eventbus.dialog.alert(alert);
            }
        },
    },
    computed: {
        msgNoRights: function() {
            let module = 'deze module';
            if (this.pageItem && this.pageItem.menu && this.pageItem.menu.rootname) {
                module = `module ${this.pageItem.menu.rootname}`;
            } else {
                // Do nothing.
                // console.log('msgNorights: pageItem.menu is empty. pageItem: ', this.pageItem, this.route); 
            }
            return `Uw organisatie heeft geen toegang tot ${module} of u heeft geen rechten het scherm te laden.`;
        },
        compDisabled: function() {
            if (this.disabled) {
                return true;
            }
            if (this.pageItem && !this.pageItem.isAuthorized) {
                return true;
            }
            return false;
        },
        compText: function() {
            if (this.pageItem && this.pageItem.meta && this.pageItem.meta.menu) {
                return this.pageItem.meta.menu.text;
//                return this.showRootText ? this.pageItem.meta.menu.rootname : this.pageItem.meta.menu.text;
            }
            return ;
        },
        compTitle: function() {
            if (this.pageItem && !this.pageItem.isAuthorized) {
                return this.msgNoRights;
            }
            return null;
        },
        compTo: function() {
            if (this.pageItem && this.pageItem.isAuthorized) {
                return this.path || this.pageItem.path;
            }
            return null;
        },
        compIcon: function() {
            if (this.icon) {
                return this.icon;
            }
            let type = this.type;
            type = type || this?.pageItem?.meta?.menu?.icon;
            type = type || this?.pageItem?.meta?.menu?.type;
            return icon.forType(type) ?? "";
        }
    },
    mounted() {        
        var matchable = this.route || this.path;
        this.pageItem = navigation.getRoute(matchable) || {};
        window.pi = this.pageItem
    },

}
</script>