<template>

        <v-list dense class="shortcuts">
            <v-list-item v-for="(route, i) in menuItems" :key="i" :to="route.path" link>
                <v-list-item-content>
                    <v-list-item-icon>
                        <Icon :type="route.meta.menu.icon"></Icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="route.meta.menu.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>      


</template>

<script>

import RouteMenuItem from "@controls/menu/RouteMenuItem.vue";
import navigation from '@app/navigation'
import Icon from '@controls/icons/Icon'

export default {
    name: 'Sidemenu',
    components: {
        RouteMenuItem, Icon
    },
    setup() {
        return { 
            menuItems: navigation.menu,
        };
    }
}

</script>
