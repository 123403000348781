<template>
      <Frame>
        <template v-slot:logo>
            <slot name="logo">
                <img alt="Optimit" src="@shared/assets/optimit-logo-avatar-white.png"
                    style="width:48px;height:48px;"/>
            </slot>
        </template>
        <template v-slot:menu>
            
        </template>

        <template v-slot:title v-if="$slots.title">
            <slot name="title"></slot>
        </template>
        <template v-slot:actions v-if="$slots.actions">
            <slot name="actions"></slot>
        </template>

        <template v-slot:dialogs>
            <slot name="dialogs"></slot>
        </template>

        <slot></slot>

      </Frame>  
</template>

<script setup>

// @ is an alias to /src
import Frame from '@shared/ui/mobile/Frame.vue'
import Sidemenu from "./Sidemenu";

</script>
